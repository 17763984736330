<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Invoice Builder
                    <v-spacer></v-spacer>
                    <v-select :items="invoice_templates" v-model="selected_template" label="Select a template"
                        item-text="name" return-object @change="setInvoiceTemplate()"></v-select>
                </v-card-title>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="updateInvoiceTemplate()"
                        v-if="selected_template != null">
                        Update Template
                    </v-btn>
                    <v-btn color="green darken-1" text @click="createInvoiceTemplate()">
                        New Template
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-5">
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-img :src="api_url + 'frontend/images/' + get_user.company_logo"></v-img>
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="3">
                            {{ get_user.address }}
                            <br>
                            {{ get_user.town }}
                            <br>
                            {{ get_user.county }}
                            <br>
                            Eircode - {{ get_user.eircode }}
                            <br>
                            Tel: {{ get_user.mobile }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <p><b>Date of Invoice:</b></p>
                            <p><b>Name of Deceased:</b></p>
                            <p><b>Account To:</b></p>
                        </v-col>
                        <v-col cols="3">
                            <p>{{ $date().format('DD/MM/YYYY') }}</p>
                            <p>{{ client.get_user_info.name }}</p>
                            <p>{{ client.get_user_info.representative ?? 'Reps of ' + client.get_user_info.name }}</p>
                        </v-col>
                        <v-col cols="3">
                            <p><b>Date of Death:</b></p>
                            <p><b>Address:</b></p>
                        </v-col>
                        <v-col cols="3">
                            <p>{{ $date(client.get_user_info.date_of_death).format('DD/MM/YYYY') }}</p>
                            <p>{{ client.get_user_info.address }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <b>Funeral Director Services</b>
                        </v-col>
                        <v-col cols="4">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.funeral_director_payments"
                        :key="'funeral_director_payments' + index" no-gutters>
                        <v-col cols="1">
                            <v-btn @click="invoice.funeral_director_payments.splice(index, 1)" icon>
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field v-model="item.name" dense placeholder="Description"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="item.amount" dense type="number" class="ml-5">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <v-btn @click="addService()" dark>Add</v-btn>
                        </v-col>
                        <v-col cols="4" v-if="invoice.funeral_director_payments.length > 0">
                            Subtotal: €{{ subtotal1 }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-3">
                        <v-col cols="8">
                            <b>Payments Made on Your Behalf</b>
                        </v-col>
                        <v-col cols="4">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.payments_made" :key="'payments_made' + index" no-gutters>
                        <v-col cols="1">
                            <v-btn @click="invoice.payments_made.splice(index, 1)" icon>
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field v-model="item.name" dense placeholder="Description"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="item.amount" dense type="number" class="ml-5">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <v-btn @click="addPayment()" dark>Add</v-btn>
                        </v-col>
                        <v-col cols="4" v-if="invoice.payments_made.length > 0">
                            Subtotal: €{{ subtotal2 }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4">
                            Total: €{{ total }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <b>Professional Service Fee Includes:</b>
                            <wysiwyg v-model="invoice.notes" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeInvoiceBuilderDialog()">
                        Cancel
                    </v-btn>
                    <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="createInvoice()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        <label v-if="client.invoice">
                            Update
                        </label>
                        <label v-else>
                            Create
                        </label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value', 'client'
    ],
    data: () => ({
        api_url: null,
        invoice: {
            funeral_director_payments: [
                { name: 'Professional Service Charge', amount: null },
                { name: 'Coffin', amount: null },
                { name: 'Ashes/Casket/Urn', amount: null },
                { name: 'Hygienic Treatment/Embalming and Presentation', amount: null },
                { name: 'Funeral Home Use', amount: null },
                { name: 'Additional Movements of the Deceased', amount: null },
                { name: 'Funeral Cars', amount: null },
                { name: 'Temporary Grave Markers', amount: null },
            ],
            payments_made: [
                { name: 'Cemetery Fees', amount: null },
                { name: 'Cremation Fees', amount: null },
                { name: 'Church/Ceremony Venue Fees', amount: null },
                { name: 'Priest/Minister/Celebrant Fees', amount: null },
                { name: 'Musicians/Soloist', amount: null },
                { name: 'Funeral Flowers', amount: null },
                { name: 'Funeral Notices (Radio/Newspaper/Online)', amount: null },
                { name: 'Live Streaming/Recording', amount: null },
                { name: 'Grave Digging', amount: null },
            ],
            notes: null,
        },
        invoice_templates: [],
        selected_template: null,
    }),
    mounted() {
        this.getInvoiceTemplates()
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user'
        }),
        subtotal1() {
            let subtotal = 0
            this.invoice.funeral_director_payments.forEach(payment => {
                if (payment.amount > 0) {
                    subtotal += parseFloat(payment.amount)
                }
            })
            return subtotal
        },
        subtotal2() {
            let subtotal2 = 0
            this.invoice.payments_made.forEach(payment => {
                if (payment.amount > 0) {
                    subtotal2 += parseFloat(payment.amount)
                }
            })
            return subtotal2
        },
        total() {
            return this.subtotal1 + this.subtotal2
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit('auth/setMessage',
                { show: true, message: message },
                { root: 1 })
        },
        setInvoiceTemplate() {
            this.invoice.funeral_director_payments = JSON.parse(this.selected_template.funeral_director_payments)
            this.invoice.payments_made = JSON.parse(this.selected_template.payments_made)
            this.invoice.notes = this.selected_template.notes
        },
        async getInvoiceTemplates() {
            let payload = {
                user_id: this.get_user.id,
            }
            await this.$axios.get('auth/fd_client/get_invoice_templates', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.invoice_templates = data.data
                        this.setData()
                    }
                })
        },
        setData() {
            if (this.client.invoice != null) {
                this.invoice.funeral_director_payments = JSON.parse(this.client.invoice.funeral_director_payments)
                this.invoice.payments_made = JSON.parse(this.client.invoice.payments_made)
                this.invoice.notes = this.client.invoice.notes
            }
            else {
                this.invoice.funeral_director_payments = JSON.parse(this.client.cost_estimate.funeral_director_payments)
                this.invoice.payments_made = JSON.parse(this.client.cost_estimate.payments_made)
            }
        },
        closeInvoiceBuilderDialog() {
            this.$emit('closeInvoiceBuilderDialog')
        },
        addService() {
            this.invoice.funeral_director_payments.push({ name: '', amount: null })
        },
        addPayment() {
            this.invoice.payments_made.push({ name: '', amount: null })
        },
        async createInvoice() {
            let payload = {
                ...this.invoice,
                total: this.total,
                client_id: this.client.client_id,
            }
            await this.$axios.post('auth/fd_client/create_or_update_invoice', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('closeInvoiceBuilderDialog')
                        this.$store.dispatch('fd_client/get_clients')
                        this.$store.dispatch('fd_client/set_client', data.data)
                    }
                })
        },
        async createInvoiceTemplate() {
            let payload = {
                user_id: this.get_user.id,
                funeral_director_payments: this.invoice.funeral_director_payments,
                payments_made: this.invoice.payments_made,
                notes: this.invoice.notes,
            }
            await this.$axios.post('auth/fd_client/create_invoice_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar(data.message)
                        this.invoice_templates = data.data
                    }
                })
        },
        async updateInvoiceTemplate() {
            let payload = {
                funeral_director_payments: this.invoice.funeral_director_payments,
                payments_made: this.invoice.payments_made,
                notes: this.invoice.notes,
            }
            await this.$axios.post(`auth/fd_client/update_invoice_template/${this.selected_template.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar(data.message)
                        this.invoice_templates = data.data
                    }
                })
        },
    },
    watch: {
        value() {
            this.setData()
        }
    }
}
</script>

<style scoped lang="scss">
p,
b,
div {
    color: black;
}

p,
div {
    font-weight: 500;
}

b {
    font-weight: 800;
}
</style>