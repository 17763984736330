import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './store/index.js'
import axios from './plugins/axios.js'
import VueDayjs from 'vue-dayjs-plugin'
import VCalendar from 'v-calendar'
import VueExcelXlsx from 'vue-excel-xlsx'
import wysiwyg from 'vue-wysiwyg'
import VueGtag from 'vue-gtag'

/**
 * layouts
 */
import Blank from './layouts/BlankLayout.vue'
import Admin from './layouts/AdminLayout.vue'
import Client from './layouts/ClientLayout.vue'
import FuneralDirector from './layouts/FuneralDirector.vue'
import BlankInput from './layouts/BlankInputLayout.vue'
import Survey from './layouts/SurveyLayout.vue'
import PublicLayout from './layouts/PublicLayout.vue'
import SolicitorLayout from './layouts/SolicitorLayout.vue'
import WillLayout from './layouts/WillLayout.vue'
Vue.component('blank-layout', Blank)
Vue.component('admin-layout', Admin)
Vue.component('client-layout', Client)
Vue.component('funeral-director-layout', FuneralDirector)
Vue.component('blank-input-layout', BlankInput)
Vue.component('survey-layout', Survey)
Vue.component('public-layout', PublicLayout)
Vue.component('solicitor-layout', SolicitorLayout)
Vue.component('will-layout', WillLayout)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.use(VueGtag, {
  config: {
    id: 'AW-11041626133',
    params: {
      send_page_view: false
    }
  }
})

Vue.config.productionTip = false

let wysiwyg_config = {
  hideModules: { 'image': true }
}

Vue.use(axios)
Vue.use(VueDayjs)
Vue.use(VueExcelXlsx)
Vue.use(wysiwyg, wysiwyg_config)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    const fdLogo = this.$store.getters['funeral_request/get_fd_logo']

    const iconUrl = fdLogo ? ('https://api.myfarewellwishes.ie/frontend/images/' + fdLogo) : process.env.BASE_URL + 'mfw.png'
    
    const linkEl = document.querySelector("link[rel='icon']")
    if (linkEl) {
      linkEl.setAttribute('href', iconUrl)
    }
  },
}).$mount('#app')
