<template>
    <v-container fluid :style="isMobile() ? '' : 'width: 1280px;'">
        <v-row justify="center" align="center" v-if="!get_show_survey">
            <v-card :width="isMobile() ? '100%' : '30%'" class="mt-10 mb-15" elevation="0">
                <v-card-title class="justify-center" v-if="get_survey.get_fd.company_name !== null"
                    style="word-break:break-word;white-space:pre-wrap;">
                    {{ get_survey.get_fd.company_name }}
                </v-card-title>
                <v-card-title class="justify-center" v-else>
                    {{ get_survey.get_fd.name }}
                </v-card-title>
                <v-card-subtitle class="text-center">
                    <strong>{{ get_survey.survey_name }}</strong>
                </v-card-subtitle>
                <!-- <v-card-text>
                    <v-img
                        v-if="get_survey.image !== null"
                        :src="`${image}/cover_photo/${get_survey.image}`"
                        contain
                        style="max-height: auto;"
                    />
                    <v-img
                        v-else
                        :src="require('../../assets/survey_image_2.webp')"
                        contain
                        style="max-width: auto;max-height:300px"
                    />
                </v-card-text> -->
                <v-card-actions>
                    <v-btn class="start-survey mt-5" style="text-transform: none !important;height:50px;font-size:16px;"
                        dark block color="rgb(23,44,84)" small @click="set_survey_state(get_show_survey)">
                        START
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-row justify="center" align="center" class="mt-15" v-else>
            <StepperComponent />
        </v-row>
        <!-- <component v-if="fd_id == 85" :is="`script`" src="https://www.facebook.com/tr?id=1064355738247178&ev=PageView&noscript=1"></component> -->
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StepperComponent from './stepper/StepperComponent.vue'
export default {
    components: {
        StepperComponent
    },
    props: [
    ],
    data: () => ({
        // e6: 1,
        questions: [],
        fd_id: 0
    }),
    async mounted() {
        await this.get_data(this.$router.currentRoute.params.token)
        this.$store.commit('survey/set_clear_answer_r_state')
        this.scrollToElement()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_survey: 'survey/get_survey',
            get_show_survey: 'survey/get_show_survey',
            get_question: 'survey/get_question',
            get_el_index: 'survey/get_el_index'
        })
    },
    methods: {
        async scrollToElement() {
            await this.$nextTick();
            document.getElementsByClassName('start-survey')[0].scrollIntoView({ behavior: 'smooth' })
        },
        async get_data(data) {
            console.log(this.$router.currentRoute.name)
            if (this.$router.currentRoute.name == 'Survey New') {
                const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
                let result = 0

                for (let i = 0; i < data.length; i++) {
                    result = result * 62 + chars.indexOf(data[i])
                }

                let id = result.toString().substring(0, result.toString().length - 6)
                console.log(id)

                await this.$axios.get('/non_auth/decode_survey_new', {
                    data: id
                })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('survey/set_survey', data.data)
                            this.fd_id = data.data.fd_id
                            if (data.data.fd_id == 85) {
                                window.fbq('init', '1064355738247178');
                                window.fbq('track', 'PageView');
                            }
                        }
                    })
            }
            else {
                await this.$axios.get('/non_auth/decode_survey', {
                    data: data
                })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('survey/set_survey', data.data)
                            this.fd_id = data.data.fd_id
                            if (data.data.fd_id == 85) {
                                window.fbq('init', '1064355738247178');
                                window.fbq('track', 'PageView');
                            }
                        }
                    })
            }

            this.$store.commit('survey/set_show_survey_false', false)
            this.$store.commit('survey/set_questions', 0)
        },
        set_survey_state(state) {
            this.$store.dispatch('survey/set_show_survey', state)
        },
        next_step() {
            if (this.get_el_index < this.get_question.length) {
                this.$store.dispatch('survey/set_el_index', this.get_el_index + 1)
            }
        },
        prev_step() {
            if (this.get_el_index > 1) {
                this.$store.dispatch('survey/set_el_index', this.get_el_index - 1)
            }
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>