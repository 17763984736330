<template>
    <v-container fluid
        style="background: linear-gradient(179.14deg, #FFFFFF 22.3%, #172C54 368.35%, #172C54 368.35%); height: 100%;"
        class="pa-0 ma-0">
        <v-app-bar :color="(get_survey.get_fd) ? get_survey.get_fd.color : '#193B5B'" dark height="150" app>
            <v-card :color="(get_survey.get_fd) ? get_survey.get_fd.color : '#193B5B'" v-if="!isMobile()" elevation="0">
                <!--<div
                    v-if="Object.keys(get_survey).length > 0"
                >
                    <v-card-title>
                        <v-img
                            v-if="get_survey.image !== null"
                            :src="`${img}/cover_photo/${get_survey.image}`"
                            contain
                            max-height="150"
                            max-width="150"
                            @click="$router.push({name: '/'})"
                        />
                        <v-img
                            v-else
                            :src="require('../assets/surveydefault.webp')"
                            contain
                            max-width="120"
                            max-height="80"
                        />
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <strong
                            class="mt-10"
                        >{{get_survey.survey_name}}</strong>
                    </v-card-text>
                </div>
                <div
                    v-else
                >-->
                <v-card-title v-if="get_survey.get_fd">
                    <v-img v-if="get_survey.get_fd.company_logo"
                        :src="`${api_url}frontend/images/${get_survey.get_fd.company_logo}`" contain max-width="320"
                        max-height="120" />
                </v-card-title>
                <!--</div>-->
            </v-card>

            <v-card :color="(get_survey.get_fd) ? get_survey.get_fd.color : '#193B5B'" v-else elevation="0">
                <v-card-title v-if="Object.keys(get_survey).length > 0">
                    <v-img :src="`${api_url}frontend/images/${get_survey.get_fd.company_logo}`" contain max-width="180"
                        max-height="120" />
                </v-card-title>
                <!--</div>-->
            </v-card>
            <v-spacer />
            <!-- <v-img
                :src="require('../assets/logo.png')"
                contain
                max-height="150"
                max-width="150"
            /> -->
        </v-app-bar>
        <slot />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        api_url: null
    }),
    computed: {
        ...mapGetters({
            get_survey: 'survey/get_survey',
        })
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: inherit;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 7px;
}
</style>